@font-face {
    font-family: "Gilroy Light";
    src: url("/fonts/Gilroy/Gilroy-Light.ttf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "Gilroy Bold";
    src: url("/fonts/Gilroy/Gilroy-Bold.ttf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "Gilroy Bold Italic";
    src: url("/fonts/Gilroy/Gilroy-ExtraBoldItalic.ttf") format("truetype");
    font-display: swap;
    font-style: italic;
}

@font-face {
    font-family: "Gilroy Medium";
    src: url("/fonts/Gilroy/Gilroy-Medium.ttf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "Gilroy Light Italic";
    src: url("/fonts/Gilroy/Gilroy-LightItalic.ttf") format("truetype");
    font-display: swap;
    font-style: italic;
}

@font-face {
    font-family: "Gilroy Heavy";
    src: url("/fonts/Gilroy/Gilroy-Heavy.ttf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "Gilroy Regular";
    src: url("/fonts/Gilroy/Gilroy-Regular.ttf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "Gotham Light";
    src: url("/fonts/Gotham/GothamHTF-Light.eot");
    src: url("/fonts/Gotham/GothamHTF-Light.eot?#iefix")
            format("embedded-opentype"),
        url("/fonts/Gotham/GothamHTF-Light.woff") format("woff"),
        url("/fonts/Gotham/GothamHTF-Light.ttf") format("truetype");
    src: url("/fonts/Gotham/GothamHTF-Light.ttf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "Gotham Bold";
    src: url("/fonts/Gotham/GothamHTF-Bold.eot");
    src: url("/fonts/Gotham/GothamHTF-Bold.eot?#iefix")
            format("embedded-opentype"),
        url("/fonts/Gotham/GothamHTF-Bold.woff") format("woff"),
        url("/fonts/Gotham/GothamHTF-Bold.ttf") format("truetype");
    src: url("/fonts/Gotham/GothamHTF-Bold.ttf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "Gotham Medium";
    src: url("/fonts/Gotham/GothamHTF-Medium.eot");
    src: url("/fonts/Gotham/GothamHTF-Medium.eot?#iefix")
            format("embedded-opentype"),
        url("/fonts/Gotham/GothamHTF-Medium.woff") format("woff"),
        url("/fonts/Gotham/GothamHTF-Medium.ttf") format("truetype");
    font-display: swap;
}
@font-face {
    font-family: "Gotham Thin";
    src: url("/fonts/Gotham/GothamHTF-Thin.eot");
    src: url("/fonts/Gotham/GothamHTF-Thin.eot?#iefix")
            format("embedded-opentype"),
        url("/fonts/Gotham/GothamHTF-Thin.woff") format("woff"),
        url("/fonts/Gotham/GothamHTF-Thin.ttf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "Gotham Black";
    src: url("/fonts/Gotham/GothamHTF-Black.eot");
    src: url("/fonts/Gotham/GothamHTF-Black.eot?#iefix")
            format("embedded-opentype"),
        url("/fonts/Gotham/GothamHTF-Black.woff") format("woff"),
        url("/fonts/Gotham/GothamHTF-Black.ttf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "Gotham Book";
    src: url("/fonts/Gotham/GothamHTF-Book.eot");
    src: url("/fonts/Gotham/GothamHTF-Book.eot?#iefix")
            format("embedded-opentype"),
        url("/fonts/Gotham/GothamHTF-Book.woff") format("woff"),
        url("/fonts/Gotham/GothamHTF-Book.ttf") format("truetype");
    font-display: swap;
}
body {
    font-family: "Gilroy Medium";
    color: #595959;
}

label {
    color: #595959;
    font-weight: 500;
}

input {
    border: 1px solid #979797;
    border-radius: 0;
    padding: 6px 0px 6px 6px;
    margin: 12px 0px;
    line-height: 2em;
    width: 98%;
}

strong {
    font-family: "Gilroy Bold";
}

a,
.link {
    color: #005275;
    cursor: pointer;
}
a:hover,
.link:hover {
    text-decoration: underline;
}
.container {
    padding: 25px 15px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 768px) {
    .container {
        width: 750px;
    }
}
@media (min-width: 992px) {
    .container {
        width: 970px;
    }
}
@media (min-width: 1200px) {
    .container {
        width: 1170px;
    }
}

.video-transcription-link {
    display: block;
    margin-top: 5px;
    font-size: 0.9rem;
    color: #595959;
    text-decoration: underline;
}

.text-center {
    text-align: center;
}

.hidden {
    display: none !important;
}

.show {
    display: block !important;
}
@media (max-width: 767px) {
    .hidden-xs {
        display: none !important;
    }
    .show-xs {
        display: block !important;
    }
}
.visible-lg,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block,
.visible-md,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-sm,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-xs,
.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block {
    display: none !important;
}
@media (min-width: 768px) and (max-width: 991px) {
    .hidden-sm {
        display: none !important;
    }
}
.brag-new .brag-title {
    font-size: 1.5rem;
    line-height: 1.5;
}
@media (max-width: 767px) {
    .brag-new .brag-description,
    .brag-new .brag-description p {
        font-size: 0.9rem;
    }
    .brag-new .brag-description p {
        display: inline;
        padding-left: 4px;
    }
    .brag-new .brag-title {
        font-size: 1.3rem;
        line-height: 1.3;
    }
    .visible-xs {
        display: block !important;
    }
    .mobile-t-center {
        text-align: center;
    }
    .xs-block {
        display: block;
    }
}
.search-money-icon-red {
    background-image: url("/images/dolar-red.svg");
    background-repeat: no-repeat;
    height: 1.7rem;
    width: 2.7rem;
}
.search-money-icon-white {
    background-image: url("/images/dolar-white.svg");
    background-repeat: no-repeat;
    height: 1.563rem;
    width: 1.563rem;
    margin-right: 0.5rem;
    margin-left: 0.5rem;
    margin-bottom: -0.5rem;
    display: inline-block;
}

.btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 35px;
    padding: 0 10px;
    border: 0;
    border-radius: 0;
    outline: 0;
    font-size: 0.9rem;
    line-height: normal;
    text-align: center;
    text-transform: uppercase;
    vertical-align: middle;
    cursor: pointer;
    background-image: none;
    white-space: nowrap;
    user-select: none;
}
.btn-blue {
    border: 2px solid #006bb7;
    color: #214e70;
    background-color: #fff;
}
.btn-red {
    border: 2px solid #dd1215;
    color: #dd1215;
    background-color: #fff;
}
.btn-bluefull {
    color: #fff;
    background-color: #006bb7;
}
.btn.disabled,
.btn[disabled],
fieldset[disabled] .btn {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.65;
    filter: alpha(opacity=65);
    box-shadow: none;
    background-color: #595959;
    border-color: #000;
    color: #fff;
}

.MuiButton-textPrimary {
    color: #004e83 !important;
}

.text-underline {
    text-decoration: underline;
}

a,
.link {
    text-decoration: none;
}

.sitemap-list {
    padding-left: 20px;
    list-style-type: disc;
}

.sitemap-list a {
    text-decoration: underline;
    position: relative;
    display: inline-block;
}

.sitemap-sublist {
    padding-left: 20px;
    list-style-type: circle;
}

.locationdma-storeinfo:hover {
    background-color: #fff;
    border: 1px solid #8b8b8b;
}

.MuiList-root.MuiList-padding.MuiMenu-list {
    padding: 0;
}
.MuiMenuItem-root.MuiMenuItem-gutters.MuiButtonBase-root {
    padding: 0;
}
.MuiMenuItem-root.MuiMenuItem-gutters.MuiButtonBase-root.dropdownMenuItem {
    padding: 5px 10px;
}
.MuiMenuItem-root.MuiMenuItem-gutters.MuiButtonBase-root > a {
    padding: 0;
    display: flex;
}

.store-details-estimate-button {
    margin-top: 10px;
}
.store-details-estimate-button a {
    width: 234px;
    padding: 6px 8px;
}
.store-details-estimate-button span {
    margin-top: 0;
}
.store-details-estimate-button svg {
    height: 1em !important;
    width: 1em !important;
}

.store-location-page-estimate-button a {
    width: 100%;
}
.store-location-page-estimate-button span {
    margin-top: 0;
}

.appointment-button button {
    width: 234px;
    padding-left: 8px;
    padding-right: 8px;
    margin-bottom: 0px !important;
}
.appointment-button svg {
    height: 1em !important;
    width: 1em !important;
}

.banner-header {
    font-size: 2.5rem;
    margin: 0;
    line-height: 2em;
}
.banner-header-middle-text {
    font-weight: 600;
}

@media only screen and (max-width: 1024px) {
    .store-details {
        display: flex;
        flex-direction: column;
        margin-left: 40px;
    }

    .image-section {
        margin-bottom: 15px !important;
    }
}

@media only screen and (max-width: 768px) {
    .store-details {
        margin-left: 0;
        padding-left: 12px;
        flex-direction: column-reverse;
    }
    .appointment-button button {
        padding-left: 4px;
        padding-right: 4px;
    }
    .pac-container {
        z-index: 1300;
    }
}
