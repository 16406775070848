@font-face {
    font-family: 'maaco-icons';
    src: url('maaco-icons.woff');
    display: inline-block;
    text-align: center;
    text-decoration: inherit;
    text-transform: none;
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
.icon-blue {
    color: #006bb7;
}
.icon-red {
    color: #b30f11;
}
[class*=icon-]:before, [class^=icon-]:before {
    display: inline-block;
    text-align: center;
    text-decoration: inherit;
    text-transform: none;
    font-weight: 400;
    font-style: normal;
    font-variant: normal;
    font-family: maaco-icons!important;
    line-height: 1em;
    speak: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.icon-location:before {
    content: '\e814';
}
.icon-quote-square:before {
    content: '\e82f';
}
.icon-calendar-plus:before {
    content: '\e82a';
}
.icon-garage:before {
    content: '\e819';
}
.icon-tags:before {
    content: '\e816';
}
.icon-wrench:before {
    content: '\e818';
}
.icon-dollar:before {
    content: '\e817';
}
.icon-map-marker:before {
    content: '\e815';
}
.icon-info-circled:before {
    content: '\e810';
}
.icon-feedback:before {
    content: '\e83d';
}
.icon-medium:before {
    font-size: 175%;
}

.icon-wifi:before {
    content: '\e84e';
}
.icon-coffee:before {
    content: '\e850';
}
.icon-tv:before {
    content: '\e854';
}
.icon-medal:before {
    content: '\e851';
}
.icon-senior62:before {
    content: '\e84a';
}
.icon-aaa:before {
    content: '\e84b';
}
.icon-community:before {
    content: '\e84c';
}
.icon-loyalty:before {
    content: '\e84d';
}
.icon-printer:before {
    content: '\e83b';
}
.icon-mail:before {
    content: '\e81e';
}
.icon-text:before {
    content: '\e83c';
}
.icon-menu:before {
    content: '\e812';
}
.icon-large {
    display: inline-block;
    font-size: 230%;
}
.icon-xlarge {
    display: inline-block;
    font-size: 250%;
}
.icon-xxlarge {
    display: inline-block;
    font-size: 260%;
}